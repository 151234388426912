import type React from 'react'
import Image from 'next/image'

import type { ComponentAnalyticsClickProps } from '@cms/analytics/types'
import useNormalizeReference, {
  type NormalizeReferenceProps,
} from '@cms/hooks/useNormalizeReference'
import { FALLBACK_IMAGES } from '@cms/utils/constants'
import { getDefaultImageLoader } from '@cms/utils/utils'
import type { ContactProps } from '@knauf-group/ct-designs/components/core/Contact'
import {
  ContactsGallery,
  type ContactsGalleryProps,
} from '@knauf-group/ct-designs/components/core/ContactsGallery'
import type { ImageWeb, ReferenceWeb } from '@knauf-group/ct-designs/utils/types'
import type {
  TypeContactSkeleton,
  TypeContentContactGallerySkeleton,
  TypeExternalReferenceSkeleton,
} from '@knauf-group/ct-shared-nextjs/web/contentful/generated-types'
import { CONTENTFUL_TYPES } from '@knauf-group/ct-shared-nextjs/web/utils/constants'
import type { ContentEntry } from '@knauf-group/ct-shared-nextjs/web/utils/types'

const buildExternalRefEntry = (url: string) => {
  return {
    sys: {
      contentType: {
        sys: {
          type: 'Link',
          linkType: 'ContentType',
          id: CONTENTFUL_TYPES.EXTERNAL_REFERENCE,
        },
      },
    },
    fields: {
      url,
    },
  } as ContentEntry<TypeExternalReferenceSkeleton>
}

const formatContactsEntry = (
  { sys, fields: contact }: ContentEntry<TypeContactSkeleton>,
  normalizeReference: (props: NormalizeReferenceProps) => ReferenceWeb,
): ContactProps => {
  const photo: ImageWeb = {
    url: contact?.photo?.[0].url,
    title: contact?.name,
    fallbackUrl: FALLBACK_IMAGES.CONTACTS,
  }
  const analyticsProps: ComponentAnalyticsClickProps = {
    targetType: 'link',
    cmsComponent: 'content_contact_gallery',
  }
  const phoneReference =
    contact?.telephone &&
    normalizeReference({
      reference: buildExternalRefEntry(`tel:${contact?.telephone}`),
      referenceLabelOverride: contact?.telephone,
      analyticsProps,
    })
  const emailReference =
    contact?.email &&
    normalizeReference({
      reference: buildExternalRefEntry(`mailto:${contact?.email}`),
      referenceLabelOverride: contact?.email,
      analyticsProps,
    })

  return {
    id: sys.id,
    name: contact?.name,
    photo,
    nextImageComponent: Image,
    // empty loader triggers the default one which applies the Optimization API which serves them directly from the app. See https://nextjs.org/docs/pages/building-your-application/optimizing/images#loaders
    imageLoader: getDefaultImageLoader(),
    job: contact?.jobPosition,
    company: contact?.companyName,
    fax: contact?.fax,
    phone: contact?.telephone,
    phoneReference,
    email: contact?.email,
    emailReference,
    address: contact?.address,
  }
}

const ContactsGalleryWrapper: React.FC<ContentEntry<TypeContentContactGallerySkeleton>> = ({
  fields: contactsGalleryContent,
}) => {
  const { normalizeReference } = useNormalizeReference()
  if (!contactsGalleryContent) {
    return null
  }

  const contactsGalleryProps: ContactsGalleryProps = {
    headline: contactsGalleryContent?.headline,
    description: contactsGalleryContent?.text,
    contacts: contactsGalleryContent?.contacts
      ?.filter((contact) => contact.fields) // Filter out draft content from contentful
      .map((contact) => formatContactsEntry(contact, normalizeReference)),
  }
  return <ContactsGallery {...contactsGalleryProps} />
}

export default ContactsGalleryWrapper
